import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    path: '/',
    component: () => import('../views/Home/Index.vue'),
    meta: {
      show: true
    }
  },
  {
    path: '/notice',
    component: () => import('../views/Home/Notice.vue')
  },
  {
    path: '/notice/detail',
    component: () => import('../views/Home/Detail.vue')
  },
  {
    path: '/mining',
    component: () => import('../views/Mining/Index.vue'),
    meta: {
      show: true
    }
  },
  {
    path: '/mining/record',
    component: () => import('../views/Mining/Record.vue')
  },
  {
    path: '/wallet',
    component: () => import('../views/Wallet/Index.vue'),
    meta: {
      show: true
    }
  },
  {
    path: '/wallet/record',
    component: () => import('../views/Wallet/Record.vue')
  },
  {
    path: '/mine',
    component: () => import('../views/Mine/Index.vue'),
    meta: {
      show: true
    }
  },
  {
    path: '/withdraw',
    component: () => import('../views/Withdraw.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router;
