import { get, post } from './http'

// 用户注册
export const register = p => post('/index/User/register', p);

// 用户信息
export const getUserInfo = p => get('/index/User/getUserInfo', p);

// 绑定上级
export const bindUser = p => post('/index/User/bindUser', p);

// 获取共识信息
export const getConsensus = p => get('/index/User/getConsensus', p);

// 挖矿订单
export const kuangOrder = p => get('/index/User/kuangOrder', p);

// 会员资产
export const walletList = p => get('/index/User/walletList', p);

// 参与挖矿
export const canKuang = p => post('/index/User/canKuang', p);

// 挖矿收益
export const kuangProfit = p => get('/index/User/kuangProfit', p);

// 团队用户
export const teamInfo = p => get('/index/User/teamInfo', p);

// 资金记录
export const transferLog = p => get('/index/User/transferLog', p);

// 申请提现
export const withdrawApply = p => post('/index/User/withdrawApply', p);

// 登录获取token
export const login = p => post('/admin/Login/login', p);

// 提现审核
export const editTransferLogStatus = p => post('/admin/wallet/editTransferLogStatus', p);
