<template>
  <div class="header" :style="headerStyle">
    <img class="logo" src="@/assets/img/logo.png">
    <div class="right">
      <div v-if="isShow" class="check" @click="openCheck">Check</div>
      <img class="lang" src="@/assets/img/icon_lang.png">
      <div v-if="address" class="wallet" @click="modal1.open({ view: 'AllWallets' })">{{ getEllipsis(address, '...', 3, 3) }}</div>
      <img v-else src="@/assets/img/icon_wallet1.png" @click="modal1.open({ view: 'AllWallets' })">
    </div>
  </div>
  <div class="block"></div>

  <!--绑定上级弹框-->
  <van-popup
      v-model:show="show"
      :close-on-click-overlay="false"
      overlay-class="mask"
  >
    <div class="bind">
      <div class="title">绑定上级</div>
      <div class="form">
        <div class="item">
          <div class="label">填写上级地址</div>
          <input v-model="p_address" placeholder="请填写钱包地址">
        </div>
      </div>
      <van-button :loading="loading" type="primary" block @click="handleSubmit">确认绑定</van-button>
    </div>
  </van-popup>

  <!--审核登录弹框-->
  <van-popup
      v-model:show="showCheck"
      :close-on-click-overlay="false"
      overlay-class="mask"
  >
    <div class="cWrap">
      <img class="close" src="@/assets/img/icon_close.png" @click="showCheck = false">
      <div class="bind check">
        <div class="title">Login</div>
        <div class="form">
          <div class="item">
            <input v-model="form.account" placeholder="username">
          </div>
          <div class="item">
            <input v-model="password" type="password" placeholder="password">
          </div>
          <div class="item">
            <input v-model="form.google_code" placeholder="google verification key">
          </div>
        </div>
        <van-button :loading="loading1" type="primary" block @click="handleLogin">Login</van-button>
      </div>
    </div>
  </van-popup>

  <!--选择语言弹框-->
  <van-popup
      v-model:show="showLang"
      position="right"
      :style="{ height: '100%' }"
      overlay-class="mask"
      duration="0.2"
  >
    <div class="langWrap">
      <div class="title">
        <img class="icon" src="@/assets/img/icon_lang.png">
        <div class="text">选择语言</div>
      </div>
      <div class="list">
        <div class="item active">
          <div class="text">中文</div>
          <img class="icon" src="@/assets/img/icon_select.png">
        </div>
        <div class="item">
          <div class="text">English</div>
          <img class="icon" src="@/assets/img/icon_select.png">
        </div>
        <div class="item">
          <div class="text">日本語</div>
          <img class="icon" src="@/assets/img/icon_select.png">
        </div>
        <div class="item">
          <div class="text">한국어</div>
          <img class="icon" src="@/assets/img/icon_select.png">
        </div>
      </div>
    </div>
  </van-popup>
</template>

<script setup>
  import { ref, onMounted, onBeforeUnmount, watch, reactive } from "vue";
  import {
    createWeb3Modal,
    defaultConfig, useWeb3Modal,
    useWeb3ModalAccount,
    useDisconnect
  } from '@web3modal/ethers/vue'
  import { BrowserProvider } from 'ethers'
  import { getEllipsis } from "@/utils";
  import { register, getUserInfo, bindUser, login } from "@/api/user";
  import { showToast, showSuccessToast } from 'vant'
  import { useRoute, useRouter } from "vue-router";
  import { getConfig } from '@/api'

  const projectId = 'c3e254116c559ae5e58746e69fb38787'
  const mainnet = {
    chainId: 56,
    name: 'BSC',
    currency: 'BNB',
    explorerUrl: 'https://bscscan.com',
    rpcUrl: 'https://bsc-dataseed.binance.org'
  }
  const metadata = {
    name: 'Smartcity.BNT',
    description: 'My Website description',
    url: 'https://www.smartcitybnt.com', // url must match your domain & subdomain
    icons: ['https://www.smartcitybnt.com/logo.png']
  }
  const ethersConfig = defaultConfig({
    /*Required*/
    metadata,

    /*Optional*/
    enableEIP6963: true, // true by default
    enableInjected: true, // true by default
    enableCoinbase: true, // true by default
    rpcUrl: 'https://bsc-dataseed.binance.org', // used for the Coinbase SDK
    defaultChainId: 56, // used for the Coinbase SDK
  })
  const modal = createWeb3Modal({
    ethersConfig,
    chains: [mainnet],
    projectId,
    enableAnalytics: true, // Optional - defaults to your Cloud configuration
    // themeMode: 'light',
    featuredWalletIds: [
      '20459438007b75f4f4acb98bf29aa3b800550309646d375da5fd4aac6c2a2c66'
    ]
  })
  const modal1 = useWeb3Modal()

  const address = ref(sessionStorage.getItem('address') || '')
  const emits = defineEmits(['getSigner'])
  let wAddr = useWeb3ModalAccount().address, signer
  if(wAddr.value) {
    getConfig().then(res => {
      if(res.success) {
        if(res.data.ti_address === wAddr.value) {
          isShow.value = true
        }
      }
    })
  }
  watch(wAddr, async (newVal, oldVal) => {
    try {
      const walletProvider = modal.getWalletProvider()
      const ethersProvider = new BrowserProvider(walletProvider)
      signer = await ethersProvider.getSigner()
      emits('getSigner', signer)
      if(address.value && address.value === newVal) {
        getInfo()
        return
      }
      const signature = await signer.signMessage('Link Wallet')
      if(signature) {
        modal1.close()
        address.value = newVal
        sessionStorage.setItem('address', newVal)
        register({
          address: address.value
        }).then(_ => {
          getInfo()
          window.location.reload()
        })
      }
    } catch (e) {
      if(e.reason === 'rejected'){
        disconnect()
      }
    }
  })

  const route = useRoute()
  const router = useRouter()
  const show = ref(false)
  const loading = ref(false)
  const p_address = ref('')

  async function handleSubmit() {
    if(p_address.value === '') {
      showToast('请填写钱包地址')
      return
    }
    if(p_address.value.substring(0, 2) !== '0x' || p_address.value.length !== 42) {
      showToast('请填写正确的钱包地址')
      return
    }
    try {
      const signature = await signer.signMessage('Bind to parent')
      if(signature) {
        loading.value = true
        bindUser({
          address: address.value,
          p_address: p_address.value
        }).then(res => {
          if(res.success) {
            showSuccessToast('绑定成功')
            show.value = false
          } else {
            showToast(res.msg)
          }
          loading.value = false
        })
      }
    } catch (e) {}
  }

  const headerStyle = ref({})
  onMounted(() => {
    !address.value && modal1.open({ view: 'AllWallets' })
    window.addEventListener('scroll', onScrollChange)
  })

  onBeforeUnmount(() => {
    window.removeEventListener('scroll', onScrollChange)
  })

  function onScrollChange() {
    const opacity = window.scrollY / 48 < 1 ? window.scrollY / 48 : 1
    headerStyle.value.backgroundColor = "rgba(35, 32, 30, " + opacity + ")"
  }

  const isShow = ref(false)
  function getInfo() {
    const p_address = route.query.p_address
    getUserInfo({
      address: address.value
    }).then(async res => {
      if(res.success) {
        if(!res.data.p_address) {
          if(p_address) {
            try {
              const signature = await signer.signMessage('Bind to parent')
              if(signature) {
                bindUser({
                  address: address.value,
                  p_address
                }).then(res1 => {
                  if(!res1.success) {
                    showToast(res1.msg)
                    show.value = true
                  }
                })
              }
            } catch (e) {}
          } else {
            show.value = true
          }
        }
        getConfig().then(res1 => {
          if(res1.success) {
            if(res1.data.ti_address === res.data.address) {
              isShow.value = true
            }
          }
        })
      }
    })
  }

  const showCheck = ref(false)
  const loading1 = ref(false)
  const form = reactive({
    account: '',
    pswd: '',
    google_code: ''
  })
  const password = ref('')
  function handleLogin() {
    if(form.account === '') {
      showToast('账号不能为空')
      return
    }
    if(password.value === '') {
      showToast('密码不能为空')
      return
    }
    if(form.google_code === '') {
      showToast('谷歌验证码不能为空')
      return
    }
    loading1.value = true
    form.pswd = md5(password.value)
    login(form).then(res => {
      if(res.success) {
        sessionStorage.setItem('token', res.data.token)
        router.push('/withdraw')
      } else {
        showToast(res.msg)
      }
      loading1.value = false
    })
  }

  function openCheck() {
    if(sessionStorage.getItem('token')) {
      router.push('/withdraw')
    } else {
      showCheck.value = true
    }
  }

  const showLang = ref(false)

  function disconnect() {
    const { disconnect } = useDisconnect()
    disconnect()
  }
</script>

<style scoped lang="less">
.header{
  position: fixed;
  width: 100%;
  height: 44px;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  z-index: 999;

  .logo{
    width: 132px;
  }
  .right{
    display: flex;
    align-items: center;

    img{
      width: 20px;
    }
    .check{
      margin-right: 16px;
    }
    .lang{
      margin-right: 16px;
    }
    .wallet{
      border: 1px solid #EDC29A;
      padding: 4px 10px;
      border-radius: 12px;
    }
  }
}
.block{
  height: 44px;
}
.bind{
  width: 303px;
  height: 231px;
  background: url("@/assets/img/pop_bg.png") no-repeat;
  background-size: 100% 100%;
  color: #FFFFFF;

  .title{
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 24px;
  }
  .form{
    padding: 0 20px;
    margin-bottom: 28px;

    .item{
      .label{
        font-size: 13px;
        line-height: 19px;
        margin-bottom: 8px;
      }
      input{
        font-size: 14px;
      }
    }
  }
  .van-button--primary{
    width: 198px;
    height: 40px;
    background: url("@/assets/img/btn_bg.png") no-repeat;
    background-size: 100% 100%;
    margin: 0 auto;
    border: none;
    color: #542E21;
    font-weight: bold;

    :deep(.van-button__text){
      font-size: 16px;
    }
  }
}
.cWrap{
  position: relative;
  padding-top: 34px;

  .close{
    width: 24px;
    position: absolute;
    right: 0;
    top: 0;
  }
  .check{
    height: 305px;
    background: url("@/assets/img/pop_bg3.png") no-repeat;
    background-size: 100% 100%;
    color: #FFFFFF;

    .form{
      .item{
        margin-top: 12px;
      }
      .item:first-child{
        margin-top: 0;
      }
    }
  }
}
.langWrap{
  padding: 56px 16px 0 16px;
  height: 100%;
  background: #111111;

  .title{
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    .icon{
      width: 20px;
      margin-right: 8px;
    }
    .text{
      font-size: 16px;
      font-weight: bold;
      line-height: 22px;
    }
  }
  .list{
    .item{
      width: 214px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 12px 0 20px;
      background: linear-gradient( 134deg, rgba(234,187,150,0.0392) 0%, rgba(234,187,150,0.102) 55%, rgba(234,187,150,0.08) 68%, rgba(234,187,150,0.0392) 100%);
      border-radius: 8px 8px 8px 8px;
      border: 1px solid #46352E;
      margin-bottom: 10px;

      .text{
        color: #6C6764;
        font-size: 14px;
      }
      .icon{
        width: 11px;
        display: none;
      }
    }
    .active{
      .text{
        color: #FFFFFF;
      }
      .icon{
        display: block;
      }
    }
  }
}
.van-popup{
  background: transparent;
}
.mask{
  background: rgba(0,0,0,0.8);
}
</style>
