<template>
  <div class="tabBar">
    <van-tabbar
        v-model="active"
        active-color="#FFFFFF"
        inactive-color="#6C6764"
        placeholder
        :border="false"
    >
      <van-tabbar-item replace to="/">
        <span>首页</span>
        <template #icon="props">
          <img :src="props.active ? icon.active : icon.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/mining">
        <span>矿池</span>
        <template #icon="props">
          <img :src="props.active ? icon1.active : icon1.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/wallet">
        <span>钱包</span>
        <template #icon="props">
          <img :src="props.active ? icon2.active : icon2.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/mine">
        <span>我的</span>
        <template #icon="props">
          <img :src="props.active ? icon3.active : icon3.inactive" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script setup>
  import { ref, watch } from 'vue'
  import { useRoute } from "vue-router";

  const route = useRoute()
  const active = ref(0)
  switch (route.path) {
    case '/':
      active.value = 0
      break;
    case '/mining':
      active.value = 1
      break;
    case '/wallet':
      active.value = 2
      break;
    case '/mine':
      active.value = 3
      break;
  }
  watch(route, newVal => {
    switch (newVal.path) {
      case '/':
        active.value = 0
        break;
      case '/mining':
        active.value = 1
        break;
      case '/wallet':
        active.value = 2
        break;
      case '/mine':
        active.value = 3
        break;
    }
  })
  const icon = {
    active: require('@/assets/img/icon_home_active.png'),
    inactive: require('@/assets/img/icon_home.png'),
  }
  const icon1 = {
    active: require('@/assets/img/icon_pool_active.png'),
    inactive: require('@/assets/img/icon_pool.png'),
  }
  const icon2 = {
    active: require('@/assets/img/icon_wallet_active.png'),
    inactive: require('@/assets/img/icon_wallet.png'),
  }
  const icon3 = {
    active: require('@/assets/img/icon_mine_active.png'),
    inactive: require('@/assets/img/icon_mine.png'),
  }
</script>

<style scoped lang="less">
.tabBar{
  :deep(.van-tabbar){
    background: #23201E;
    border-radius: 12px 12px 0 0;
    overflow: hidden;
  }
  .van-tabbar-item--active{
    background: #23201E;
  }
}
</style>
