import { get } from './http'

// 轮播图
export const getBanner = p => get('/index/User/getBanner', p);

// 公告列表
export const artList = p => get('/index/User/artList', p);

// 公告详情
export const getArtDetail = p => get('/index/User/getArtDetail', p);

// 获取配置
export const getConfig = p => get('/index/User/getConfig', p);

// 共识订单
export const orderConsensus = p => get('/index/User/orderConsensus', p);

// 挖矿配置
export const getKuangConfig = p => get('/index/User/getKuangConfig', p);
