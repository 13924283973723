import { createApp } from 'vue'
import App from './App.vue'

import router from './router'
import 'vant/lib/index.css';
import 'amfe-flexible'

import { Swipe, SwipeItem, Image as VanImage, Tabbar, TabbarItem, Popup, Button, Field } from 'vant'

const app = createApp(App)
app.use(router)
app.use(Swipe).use(SwipeItem).use(VanImage).use(Tabbar).use(TabbarItem).use(Popup).use(Button).use(Field)
app.mount('#app')
