<template>
  <Header v-if="route.meta.show" @getSigner="getSigner" />
  <router-view :signer="signer" />
  <TabBar v-if="route.meta.show" />
</template>

<script setup>
  import { ref } from 'vue'
  import Header from '@/components/Header.vue'
  import TabBar from "@/components/TabBar.vue";
  import { useRoute } from "vue-router";

  const route = useRoute()
  const signer = ref({})
  async function getSigner(obj) {
    signer.value = obj
  }
</script>

<style>
  @import "./assets/css/base.less";
</style>
